import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { MenuNavigation } from "../components/MenuNavigation";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { useRouteMatch } from "react-router-dom";
import config from "../../../config";
import AOS from "aos";
import "aos/dist/aos.css";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const restaurantURL = process.env.REACT_APP_SITE;

export const EloyaltyResetPW = () => {
  const { params } = useRouteMatch();

  useEffect(() => {
    document.title = "Reset Password";
  }, []);

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };

  const [password, setPassword] = useState();
  const [cpassword, setCPassword] = useState();
  const history = useHistory();

  function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siteMedSrc + "servingintel-logo-bw" + ".svg"}
            title="ServingIntel"
            alt="Point of Sale: Senior Living POS System"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </Link>
      </Typography>
    );
  }

  useEffect(() => {
    const eloyaltyContentText = config.eloyaltycontent;

    seteloyaltyContent(eloyaltyContentText);
  }, []);

  const [eloyaltyContent, seteloyaltyContent] = useState("");

  const theme = createTheme();

  const submit = () => {
    if (password != cpassword) {
      Swal.fire({
        title: "Password Confirmation",
        text: "Password did not match.",
        icon: "info",
        confirmButtonText: "OK",
      });
      return false;
    }

    if (!password || !cpassword || !params.resetToken) {
      Swal.fire({
        title: "Invalid Input",
        text: "Fields marked with * are required",
        icon: "Info",
        confirmButtonText: "OK",
      });
      return false;
    }

    const url =
      "https://ecommv2.servingintel.com/customer/eloyalty-reset-password/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId;
    axios
      .post(url, {
        verification: params.resetToken,
        password: password,
      })
      .then((response) => {
        if (response.data) {
          Swal.fire({
            title: "Success!",
            text: "Your password has been changed.",
            icon: "success",
            confirmButtonText: "OK",
          });
          history.push("/login");
        } else {
          Swal.fire({
            title: "Failed!",
            text: "Invalid Verification Code.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    AOS.init({
      duration: 900,
    });
  }, []);

  return (
    <div
      className="customBg"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      data-aos="fade-top"
    >
      <MenuNavigation />
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`${restaurantName}: ${eloyaltyContent.title}`}</title>
            <link rel="canonical" href={`${restaurantURL}change-passwordd`} />
            <meta
              name="description"
              content={`${eloyaltyContent.description}`}
            />
            <meta name="keywords" content={`${eloyaltyContent.keywords}`} />
            <meta
              property="og:title"
              content={`${restaurantName}: ${eloyaltyContent.title}`}
            />
            <meta
              property="og:description"
              content={`${eloyaltyContent.description}`}
            />
            <meta
              property="twitter:title"
              content={`${restaurantName}: ${eloyaltyContent.title}`}
            />
            <meta
              property="twitter:description"
              content={`${eloyaltyContent.description}`}
            />
          </Helmet>
        </HelmetProvider>
        <Container
          component="main"
          className="eLoyaltyBg"
          data-aos-easing="ease-in-out"
          data-aos-delay="200"
          data-aos="fade-top"
          maxWidth="md"
          style={{ marginTop: "1rem" }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
            data-aos-delay="500"
            data-aos="fade-top"
          >
            <Typography
              component="h4"
              className="white"
              variant="h4"
              style={{ marginTop: "30px", fontWeight: "550" }}
            >
              Welcome to
            </Typography>
            <a href={`${restaurantURL}`} target="_self">
              <img
                className="Logo title"
                style={{ padding: "10px 0", width: "150px" }}
                src={siteMedSrc + "logo" + ".webp"}
                width="100%"
                height="100%"
                title={`${restaurantName} | ${eloyaltyContent.title}`}
                alt={`${restaurantName} | ${eloyaltyContent.description}`}
                loading="lazy"
              />
            </a>
            <Paper
              className="formBG"
              data-aos-delay="600"
              data-aos="fade-top"
              style={{
                boxShadow: "none",
                marginTop: "0",
                marginBottom: "1rem",
                background: "beige",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                variant="outlined"
                data-aos-delay="700"
                data-aos="fade-top"
              >
                <Typography
                  variant="contained"
                  style={{ margin: "20px auto 0px auto" }}
                >
                  An email has been sent to your email address. The email has a
                  verification code, please paste the verification code in the
                  field below to prove that you are the owner of this account.
                </Typography>
              </Box>
              <Box
                component="form"
                noValidate
                style={{
                  background: "beige",
                  width: "100%",
                  paddingBottom: "1rem",
                }}
                data-aos-delay="800"
                data-aos="fade-top"
              >
                <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  margin="dense"
                  required
                  fullWidth
                  id="password"
                  type="password"
                  label="New Password"
                  name="Password"
                />
                <TextField
                  onChange={(e) => setCPassword(e.target.value)}
                  value={cpassword}
                  margin="dense"
                  required
                  fullWidth
                  id="password2"
                  type="password"
                  label="Confirm New Password"
                  name="confirm password"
                />
              </Box>
            </Paper>
            <Button
              onClick={() => submit()}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontWeight: "550" }}
              data-aos-delay="900"
              data-aos="fade-top"
            >
              Submit
            </Button>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(EloyaltyResetPW);
