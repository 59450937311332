import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Button,
  Stack,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import * as Yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { resolver } from "../../../utils/helpers";
import { Input } from "../../../components";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";

const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const grecaptchaSitekey = process.env.REACT_APP_GRECAPTCHA_SITEKEY;

const requiredMessage = "This field is required.";
const schema = {
  name: Yup.string()
    .min(2, "Please enter at least 2 characters")
    .required(requiredMessage),
  email: Yup.string()
    .email("Please enter a valid email")
    .required(requiredMessage),
  message: Yup.string().required(requiredMessage),
};

export default function ContactCard() {
  const [value, setValue] = useState("Write your Message");
  const [disabled, setDisabled] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    message: "",
  });

  const formMethods = useForm({
    resolver: resolver(schema),
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });

  // Get only important methods
  const { handleSubmit, reset } = formMethods;

  const onSubmit = async (formData) => {
    setDisabled(true);
    const body = {
      name: formData.name,
      email: formData.email,
      contactEmails: [
        process.env.REACT_APP_CONTACT_US_EMAIL,
        process.env.REACT_APP_CONTACT_US_EMAIL_1,
        process.env.REACT_APP_CONTACT_US_EMAIL_2,
      ],
      siteName: process.env.REACT_APP_RESTAURANT_NAME,
      message: formData.message,
    };

    try {
      // axios.post(`https://ecommv2.servingintel.com/customer/contact-us/{siteId}=${process.env.REACT_APP_SITE_NAME}`, {body}).then(res => console.log('Posting data', res)).catch(err => console.log(err))

      const response = await axios.post(
        `https://ecommv2.servingintel.com/customer/contact-us/${process.env.REACT_APP_SITE_ID}`,
        body
      );
      console.log("Success", response);

      setAlertData({
        type: "success",
        message: "Thanks for your message, we will reply as soon as we can.",
      });
    } catch (error) {
      console.log("Error", error);
      setAlertData({
        type: "error",
        message: error.text,
      });
    } finally {
      setDisabled(false);
      reset({
        name: "",
        email: "",
        message: "",
      });
      setTimeout(() => {
        setAlertData({
          type: "",
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    // Render reCAPTCHA when the component mounts
    const siteKey = grecaptchaSitekey;

    // Check if grecaptcha.enterprise is defined before calling ready()
    if (window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(siteKey, { action: "submit" })
          .then((token) => {
            // Use the token as needed (e.g., include it in your form submission)
            console.log("reCAPTCHA Token:", token);
          })
          .catch((error) => {
            console.error("reCAPTCHA Error:", error);
          });
      });
    }
  }, []); // Run this effect only once when the component mounts

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <Card
      sx={{ minWidth: 275 }}
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      data-aos="fade-left"
      className="whitespace"
    >
      <CardContent>
        <Typography variant="h3" gutterBottom component="div">
          Write To Us
        </Typography>
        {alertData.type && (
          <Alert
            sx={{
              margin: "16px 0",
            }}
            severity={alertData.type}
          >
            {alertData.message}
          </Alert>
        )}
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={3}>
              <Input
                variant="outlined"
                name="name"
                placeholder="Enter your name"
                autoComplete="off"
                disabled={disabled}
              />
              <Input
                variant="outlined"
                name="email"
                placeholder="Enter your email"
                autoComplete="off"
                disabled={disabled}
              />
              <Input
                variant="outlined"
                name="message"
                placeholder="Enter your message"
                autoComplete="off"
                disabled={disabled}
                multiline={true}
                rows={4}
              />

              {disabled ? (
                <Button
                  fullWidth
                  disabled
                  type="button"
                  sx={{
                    display: "flex",
                    gap: 2,
                    background: "gray",
                  }}
                >
                  <CircularProgress color="inherit" /> <span>Send</span>
                </Button>
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: buttonColor,
                    color: buttonText,
                    borderColor: buttonColor,
                  }}
                >
                  Send Message
                </Button>
              )}
            </Stack>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
}
