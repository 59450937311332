import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { withRouter, useHistory, useRouteMatch } from "react-router-dom";
import { MenuNavigation } from "../components/MenuNavigation";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FaHeart } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import BlankSpace from "../components/BlankSpace";
import { Helmet, HelmetProvider } from "react-helmet-async";
import config from "../../../config";
import AOS from "aos";
import "aos/dist/aos.css";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const restaurantURL = process.env.REACT_APP_SITE;

export const Login = () => {
  useEffect(() => {
    if (getCookie("sic_user_id") && getCookie("sic_user_id") > 0) {
      history.push("/rewards");
    }
  }, []);

  function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siteMedSrc + "servingintel-logo-bw" + ".svg"}
            title="ServingIntel"
            alt="Point of Sale: Senior Living POS System"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </Link>
      </Typography>
    );
  }

  useEffect(() => {
    const eloyaltyContentText = config.eloyaltycontent;

    seteloyaltyContent(eloyaltyContentText);
  }, []);

  const [eloyaltyContent, seteloyaltyContent] = useState("");

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  const theme = createTheme();
  const btnstyle = { margin: "10px 0 5px 0" };

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };
  const match = useRouteMatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const loginEloyalty = () => {
    const url =
      "https://ecommv2.servingintel.com/customer/login-eloyalty/" +
      serverId +
      "/" +
      siteId;
    axios
      .post(url, {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.data && response.data.UserID > 0) {
          setCookie("sic_email", response.data.Email);
          setCookie("sic_user_id", response.data.UserID);
          setCookie("sic_name", response.data.FirstName);
          setCookie("sic_firstName", response.data.FirstName);
          history.push("/rewards");
        } else {
          Swal.fire({
            title: "Login Failed!",
            text: "Invalid Credentials. If you have just registered new account recently, you need to check your email and click the activation link.",
            icon: "Warning",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (match.params.activationCode) {
      setLoading(true);
      const url =
        "https://ecommv2.servingintel.com/customer/activate-eloyalty/" +
        serverId +
        "/" +
        siteId +
        "/" +
        storeId +
        "/" +
        match.params.activationCode;
      axios
        .get(url, {})
        .then((response) => {
          if (response.data) {
            Swal.fire({
              title: "Success Activation!",
              text: "Your account has been activated. You may login now using the email and password you have provided during the registration process.",
              icon: "success",
              confirmButtonText: "OK",
            });
            setLoading(false);
          }
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);

  return (
    <div
      className="customBg"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      data-aos="fade-top"
    >
      <MenuNavigation />
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${restaurantName}: ${eloyaltyContent.title}`}</title>
          <link rel="canonical" href={`${restaurantURL}login`} />
          <meta name="description" content={`${eloyaltyContent.description}`} />
          <meta name="keywords" content={`${eloyaltyContent.keywords}`} />
          <meta
            property="og:title"
            content={`${restaurantName}: ${eloyaltyContent.title}`}
          />
          <meta
            property="og:description"
            content={`${eloyaltyContent.description}`}
          />
          <meta
            property="twitter:title"
            content={`${restaurantName}: ${eloyaltyContent.title}`}
          />
          <meta
            property="twitter:description"
            content={`${eloyaltyContent.description}`}
          />
        </Helmet>
      </HelmetProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          className="eLoyaltyBg"
          maxWidth="md"
          data-aos-easing="ease-in-out"
          data-aos-delay="200"
          data-aos="fade-top"
          style={{ maxWidth: "850px", padding: "0", marginTop: "-50px" }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
            data-aos-delay="300"
            data-aos="fade-top"
          >
            <Typography
              component="h1"
              className="white"
              variant="h4"
              style={{ marginTop: "30px", fontWeight: "550" }}
            >
              Hello, Welcome back.
            </Typography>
            {/* <a href={`${restaurantURL}`} target="_self">
              <img
                className="Logo title"
                style={{ padding: "10px 0", width: "150px" }}
                src={siteMedSrc + "logo" + ".webp"}
                width="100%"
                height="100%"
                title={`${restaurantName} | ${eloyaltyContent.title}`}
                alt={`${restaurantName} | ${eloyaltyContent.description}`}
                loading="lazy"
              />
            </a> */}
            <Typography
              className="white title"
              style={{ fontSize: "1.25rem", fontWeight: "550" }}
            >
              Login your account
            </Typography>
            <br />
            <BlankSpace />
            <Grid container spacing={1} className="title">
              {/* <Grid item xs={6}>
                <Button
                  fullWidth
                  href="/login"
                  size="large"
                  variant="contained"
                  style={{ fontWeight: "550" }}
                >
                  Login
                </Button>
              </Grid> */}
            </Grid>
            <Container
              className="formBG"
              data-aos-delay="500"
              data-aos="fade-top"
              style={{ marginTop: "-1px", padding: "0" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="formBG"
                variant="outlined"
                padding="0"
                data-aos-delay="600"
                data-aos="fade-top"
              >
                <TextField
                  onKeyUp={(e) => setEmail(e.target.value)}
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  onKeyUp={(e) => setPassword(e.target.value)}
                  margin="dense"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Button
                  onClick={() => loginEloyalty()}
                  type="submit"
                  fullWidth
                  style={btnstyle}
                  variant="contained"
                  sx={{ mt: 3, mb: 1, fontWeight: "550", margin: ".25rem 0" }}
                >
                  Sign in
                </Button>
                <Button
                  fullWidth
                  onClick={() => history.push("/register")}
                  size="large"
                  variant="contained"
                  style={{
                    fontWeight: "550",
                    backgroundColor: buttonColor,
                    borderColor: "none",
                    margin: ".25rem 0",
                  }}
                >
                  Register
                </Button>
                {/* <Typography className="horizontal">
                  <p> or</p>
                </Typography> */}
                <Button
                  href="/forgot-password"
                  fullWidth
                  variant="outlined"
                  className="btn-blue"
                  style={btnstyle}
                  sx={{ mt: 1, mb: 1, margin: ".25rem 0" }}
                >
                  Forgot Password?
                </Button>

                {/* <Button
                  href="/register"
                  fullWidth
                  className="btn-blue"
                  variant="outlined"
                  style={btnstyle}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Don't have an account? Sign up
                </Button> */}
              </Box>
            </Container>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(Login);
